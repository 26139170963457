import React from "react";
//import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
//import { Navigation, Footer, Home, About, Contact } from "./components";
import logo from './logos/1024MonochromeLogo.png';
import './css/fonts.css';
import './css/App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <div className="landingPageTitle">Movie Motors</div>
      <div className="landingPageSubTitle">Turning Cars into Stars</div>
      </header>
    </div>
  );
}

export default App;
